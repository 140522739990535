<template>
    <login-and-regist-layout :title="$t('error.title')">
        <v-container>
            <v-row>
                <v-col md="10" class="mx-auto">
                    <p class="text-center">
                        {{ $t("error.QrCodeNoValid") }}
                    </p>
                    <v-btn
                        block
                        rounded
                        class="primary mt-5"
                        @click="backToLogin"
                    >
                        back to login
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </login-and-regist-layout>
    <!-- <v-container fluid fill-height class="primary">
        <v-row align="center">
            <v-col lg="4" sm="6" xs="12" class="mx-auto">
                <v-card rounded>
                    <v-card-title
                        class="text-h5 font-weight-black justify-center pt-10"
                    >
                        {{$t("error.title")}}
                    </v-card-title>
                    <v-card-text class="py-10">
                        <v-container>
                            <v-row>
                                <v-col md="10" class="mx-auto">
                                    <p class="text-center">
                                        {{$t("error.QrCodeNoValid")}}
                                    </p>
                                    <v-btn
                                        block
                                        rounded
                                        class="primary mt-5"
                                        @click="backToLogin"
                                    >
                                        back to login
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container> -->
</template>
<script>
import { mapActions } from "vuex";
import LoginAndRegistLayout from "../layout/LoginAndRegistLayout.vue";
export default {
    components: { LoginAndRegistLayout },
    name: "Error",
    methods: {
        backToLogin() {
            this.logOut();
            this.$router.push("/login");
        },
        ...mapActions({
            logOut: "auth/customerLogout",
        }),
    },
};
</script>